<template>
  <div class="container min-height flex-column justify-content-spaceBetween align-items-center">
    <top :active="0" :dialogVisible2="topDialogVisible"></top>
    <!--    搜索框+banner-->
    <div class="banner-box width-100 flex-column justify-content-center align-items-center">
      <div class="container-box">
        <img src="../assets/image/1.png" width="100%" alt="" />
      </div>
      <div class="container-box search-box position-absolute">
        <div class="search">
          <el-input placeholder="请输入关键字搜索" v-model="findName" class="input-with-select">
            <template slot="prepend">版权登记</template>
            <!-- <el-select v-model="select" slot="prepend" placeholder="请选择">
              <el-option label="版权登记" value="1"></el-option>
              <el-option label="在售版权" value="2"></el-option>
              <el-option label="已售版权" value="3"></el-option>
              <el-option label="入驻企业" value="4"></el-option>
              <el-option label="交易人数" value="5"></el-option>
            </el-select> -->
            <el-button slot="append" @click="findCopyright">搜索</el-button>
          </el-input>
        </div>
      </div>
    </div>
    <!--    数据展示-->
    <div class="number-box width-100 flex-column justify-content-center align-items-center">
      <div class="container-box flex-row justify-content-spaceBetween align-items-center">
        <div
          class="number-item width-20 flex-row justify-content-center align-items-center"
          :class="{ 'border-right': index < 4 }"
          v-for="(item, index) in statData"
          :key="index"
        >
          <img :src="statIconList[index]" class="height-icon" alt="" />
          <div class="margin-l">
            <p class="din fs-mid-big black">{{ item.value }}</p>
            <p class="darkgrey">{{ item.title }}</p>
          </div>
        </div>
      </div>
    </div>
    <!--版权展示-->
    <div class="show-box width-100 flex-column justify-content-center align-items-center">
      <img src="../assets/image/t1.png" class="title" alt="" />
      <div class="container-box flex-row justify-content-spaceBetween align-items-start">
        <div class="left-box flex-column justify-content-center align-items-center">
          <div
            class="item width-100 pointer flex-row justify-content-start align-items-center"
            :class="copyrightTypeActive === index ? 'active fadeIn' : ''"
            @click="handleCopyrightTypeSelect(item, index)"
            v-for="(item, index) in copyrightTypeList"
            :key="index"
          >
            <p class="left-line" :class="copyrightTypeActive === index ? 'opacity-1 fadeIn' : 'opacity-0'"></p>
            <div class="width-15 textAlign-c">
              <img :src="item.icon" class="height-icon" alt="" />
            </div>
            <p class="title fs-big" :class="copyrightTypeActive === index ? 'black fw-mid' : 'white'">{{ item.title }}</p>
          </div>
        </div>
        <div v-if="copyrightList.length > 0" class="width-75 right-box flex-row justify-content-start align-items-star flex-wrap">
          <div
            @click="$router.push({ path: '/copyrightDisplayDetail', query: { id: item.id } })"
            class="item bg-white pointer"
            v-for="(item, index) in copyrightList"
            :key="index"
          >
            <van-image class="image" :src="item.copyrightImage" width="100%" fit="contain"></van-image>
            <p class="padding-Tb-10 padding-lr fs-mid black width-100 cut-text">{{ item.title }}</p>
            <p class="padding-lr fs-mid theme-blue width-100 cut-text">{{ item.copyrightPrice ? '￥' + item.copyrightPrice : '价格面议' }}</p>
          </div>
        </div>

        <div v-else class="width-75 flex-column justify-content-center align-items-center">
          <img src="../assets/image/empty.png" width="50%" />
        </div>
      </div>
      <el-button @click="$router.push('/copyrightDisplay')" plain class="more-button">查看更多</el-button>
    </div>
    <!--咨询公告-->
    <div class="news-box width-100 flex-column justify-content-center align-items-center">
      <div class="container-box flex-row justify-content-spaceBetween align-items-center">
        <div class="left">
          <div class="width-100 margin-b flex-row justify-content-spaceBetween align-items-center">
            <img src="../assets/image/t2.png" width="30%" alt="" />
            <p @click="$router.push('/information')" class="darkgrey textAlign-r fs-mid opacity-5 pointer">查看更多</p>
          </div>
          <div
            @click="$router.push({ path: '/informationDetail', query: { id: item.id } })"
            class="width-100 news-item pointer"
            v-for="(item, index) in articleList"
            :key="index"
          >
            <p class="width-100 fs-mid cut-text2 black line-height margin-bs">{{ item.title }}</p>
            <p class="width-100 grey fs-mid">{{ item.createTime }}</p>
          </div>
        </div>
        <div class="right flex-column justify-content-center align-items-end">
          <div
            class="width-80 padding-90 pointer box-shadow bg-white br-mid margin-t flex-row justify-content-start align-items-center"
            @click="clickNewsMenu(item)"
            v-for="(item, index) in newsMenu"
            :key="index"
          >
            <div class="width-25">
              <img :src="item.icon" width="80%" alt="" />
            </div>
            <div class="margin-l">
              <p class="fs-mid-big fw-bold black">{{ item.title }}</p>
              <p class="fs-mid darkgrey opacity-5 line-height2">{{ item.desc }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--版权产品-->
    <div class="product-box width-100 flex-column justify-content-center align-items-center">
      <img src="../assets/image/t5.png" class="title" alt="" />
      <div class="container-box">
        <el-row type="flex" :gutter="20" justify="center">
          <el-col :span="7" v-for="(item, index) in productList.slice(0, 3)" :key="index">
            <div class="grid-content position-relative flex-column justify-content-start align-items-center pointer" @click="$router.push({ path: '/copyrightWorksDisplayDetail', query: { id: item.id } })">
              <div class="picture-b" alt="" />
              <img :src="item.worksImage" class="picture" alt="" />
              <div class="grid-content-box">
                <p class="width-100 margin-bs cut-text flex-row justify-content-start align-items-center">
                  <el-tag effect="plain" size="mini">
                    产品名称
                  </el-tag>
                  <span class="fs-mid black margin-l grid-content-title">{{ item.title }}</span>
                </p>
                <div class="width-100 flex-row justify-content-spaceBetween align-items-center">
                  <p class="fs-big blue">{{ item.worksPrice ? '￥' + item.worksPrice : '价格面议' }}</p>
                  <p class="fs-sml white">详细>></p>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="20" justify="center">
          <el-col :span="7" v-for="(item, index) in productList.slice(3, 6)" :key="index">
            <div class="grid-content position-relative flex-column justify-content-start align-items-center pointer" @click="$router.push({ path: '/copyrightWorksDisplayDetail', query: { id: item.id } })">
              <div class="picture-b" alt="" />
              <img :src="item.worksImage" class="picture" alt="" />
              <div class="grid-content-box">
                <p class="width-100 margin-bs cut-text flex-row justify-content-start align-items-center">
                  <el-tag effect="plain" size="mini">
                    产品名称
                  </el-tag>
                  <span class="fs-mid black margin-l grid-content-title">{{ item.title }}</span>
                </p>
                <div class="width-100 flex-row justify-content-spaceBetween align-items-center">
                  <p class="fs-big blue">{{ item.worksPrice ? '￥' + item.worksPrice : '价格面议' }}</p>
                  <p class="fs-sml white pointer" @click="$router.push({ path: '/copyrightWorksDisplayDetail', query: { id: item.id } })">详细>></p>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-button plain class="more-button" @click="$router.push('/copyrightWorksDisplay')">查看更多</el-button>
    </div>
    <!--需求大厅-->
    <div class="demand-box width-100 flex-column justify-content-center align-items-center">
      <img src="../assets/image/t3.png" class="title" alt="" />
      <div class="container-box">
        <el-carousel :interval="5000" arrow="always">
          <el-carousel-item v-for="(item, index) in demandList" :key="index">
            <div class="width-100 flex-row justify-content-center align-items-center">
              <div
                class="width-30 br-mid box-shadow van-overflow-hidden margin-l margin-r pointer"
                @click="$router.push(`/demandDetail?id=${it.id}`)"
                style="margin-top: 8px"
                v-for="(it, ind) in item.item"
                :key="ind"
              >
                <!-- <div class="width-100 flex-row justify-content-center align-items-center">
                  <img :src="it.otherFile" height="100%" alt="" />
                </div> -->
                <!--                <img :src="it.otherFile" height="100%" width="100%" alt="" />-->
                <van-image class="demand-picture" :src="it.otherFile" fit="contain"></van-image>
                <div class="width-100 bg-white br-mid text-box">
                  <p class="fs-big width-100 cut-text black fw-mid margin-bs">{{ it.companyName }}</p>
                  <p class="fs-mid darkgrey width-100 cut-text margin-bs"><span class="margin-r fs-mid">需求分类</span>{{ it.copyrightTypeName }}</p>
                  <p class="fs-mid darkgrey width-100 cut-text line-height2">
                    <span class="margin-r fs-mid">版权用途</span>{{ it.copyrightUsageName }}
                  </p>
                  <p class="width-100 textAlign-r blue fs-mid">了解详情 ></p>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <el-button plain class="more-button" @click="$router.push('/demandHall')">查看更多</el-button>
    </div>
    <!--合作企业-->
    <div class="company-box width-100 flex-column justify-content-center align-items-center">
      <img src="../assets/image/t4.png" class="title" alt="" />

      <vue-seamless-scroll :data="noticeList" class="warp" :class-option="classOption">
        <div class="marquee-box width-100 flex-row justify-content-start align-items-center">
          <div class="marquee-item" v-for="(item, index) in noticeList" :key="index">
            <img :src="item" alt="" />
          </div>
        </div>
      </vue-seamless-scroll>

      <vue-seamless-scroll :data="noticeList" class="warp" :class-option="classOption2">
        <div class="marquee-box width-100 flex-row justify-content-start align-items-center">
          <div class="marquee-item" v-for="(item, index) in noticeList" :key="index">
            <img :src="item" alt="" />
          </div>
        </div>
      </vue-seamless-scroll>

      <vue-seamless-scroll :data="noticeList" class="warp" :class-option="classOption">
        <div class="marquee-box width-100 flex-row justify-content-start align-items-center">
          <div class="marquee-item" v-for="(item, index) in noticeList" :key="index">
            <img :src="item" alt="" />
          </div>
        </div>
      </vue-seamless-scroll>
    </div>

    <bottom></bottom>
  </div>
</template>
<script>
import top from '../components/top';
import bottom from '../components/footer';
import vueSeamlessScroll from 'vue-seamless-scroll';
import { indexStat } from '@/api/stat';
import { registrationList } from '@/api/tranCopyrightRegistration';
import { worksList } from '@/api/tranCopyrightWorks';
import { getTranCopyrightDemandUnAuth } from '@/api/tranCopyrightDemand';
import * as _ from 'lodash';
import { getTextByValue } from '@/util/helper';
import { getDict } from '@/api/dict';
import { articleList } from '@/api/TranArticle';

export default {
  name: 'index',
  components: {
    top,
    bottom,
    vueSeamlessScroll
  },
  data() {
    return {
      topDialogVisible: false,
      findName: '',
      select: '全部',
      productList: [
        {
          picture: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          type: '类型名称',
          title: '欧塑《活力温州》',
          price: 2000
        },
        {
          picture: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          type: '类型名称',
          title: '欧塑《活力温州》',
          price: null
        },
        {
          picture: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          type: '类型名称',
          title: '欧塑《活力温州》',
          price: 2000
        },
        {
          picture: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          type: '类型名称',
          title: '欧塑《活力温州》',
          price: 2000
        },
        {
          picture: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          type: '类型名称',
          title: '欧塑《活力温州》',
          price: null
        },
        {
          picture: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
          type: '类型名称',
          title: '欧塑《活力温州》',
          price: 2000
        }
      ],
      statData: {},
      statIconList: [
        require('../assets/image/e1.png'),
        require('../assets/image/e2.png'),
        require('../assets/image/e3.png'),
        require('../assets/image/e4.png'),
        require('../assets/image/e5.png')
      ],
      numberList: [
        {
          icon: require('../assets/image/e1.png'),
          title: '版权登记',
          count: '5万+'
        },
        {
          icon: require('../assets/image/e2.png'),
          title: '在售版权',
          count: '4.2万+'
        },
        {
          icon: require('../assets/image/e3.png'),
          title: '已售版权',
          count: '1.3万+'
        },
        {
          icon: require('../assets/image/e4.png'),
          title: '入驻企业',
          count: '158'
        },
        {
          icon: require('../assets/image/e5.png'),
          title: '交易人数',
          count: '2158'
        }
      ],
      copyrightParams: {
        copyrightTypeOn: null,
        pageNum: 1,
        pageSize: 6
      },
      copyrightWorksParams: {
        copyrightTypeOn: null,
        pageNum: 1,
        pageSize: 6
      },
      copyrightDemandParams: {
        copyrightTypeOn: null,
        pageNum: 1,
        pageSize: 6
      },
      copyrightTypeActive: 0,
      copyrightTypeList: [
        {
          icon: require('../assets/image/d1.png'),
          title: '全部',
          value: null
        },
        {
          icon: require('../assets/image/d2.png'),
          title: '美术作品',
          value: 8
        },
        {
          icon: require('../assets/image/d5.png'),
          title: '工程、产品设计',
          value: 13
        },
        {
          icon: require('../assets/image/d4.png'),
          title: '文字作品',
          value: 1
        },
        {
          icon: require('../assets/image/d3.png'),
          title: '摄影作品',
          value: 10
        },
        {
          icon: require('../assets/image/d6.png'),
          title: '音乐作品',
          value: 3
        },
        {
          icon: require('../assets/image/d7.png'),
          title: '建筑作品',
          value: 9
        }
      ],
      copyrightList: [],
      newsListL: [
        {
          title: '2023年知识产权信息公共服务重要网点交流研讨活动在深成功举办',
          date: '2023-11-29'
        },
        {
          title: '国家知识产权局办公室关于公布第二届全国知识产权公共服务机构专 利检索分析大赛获奖名单的通知',
          date: '2023-11-29'
        },
        {
          title: '国家知识产权局办公室关于公布第二届全国知识产权公共服务机构专 利检索分析大赛获奖名单的通知',
          date: '2023-11-29'
        }
      ],
      newsMenu: [
        {
          icon: require('../assets/image/b1.png'),
          title: '版权发布',
          desc: '瑞安版权直通车',
          url: '/copyrightRegistration'
        },
        {
          icon: require('../assets/image/b2.png'),
          title: '法律咨询',
          desc: '提供专业法律意见',
          url: '/legalAdvice'
        },
        {
          icon: require('../assets/image/b3.png'),
          title: '金融服务',
          desc: '版权质押一键搞定',
          url: '/financialService'
        }
      ],
      demandList: [],
      classOption: {
        limitMoveNum: 1,
        direction: 2,
        step: 0.2
      },
      classOption2: {
        limitMoveNum: 1,
        direction: 3,
        step: 0.2
      },
      noticeList: [
        'https://bqsb.rarb.cn/profile/bqjy/yll.png',
        'https://bqsb.rarb.cn/profile/bqjy/nfx.png',
        'https://bqsb.rarb.cn/profile/bqjy/yll.png',
        'https://bqsb.rarb.cn/profile/bqjy/nfx.png',
        'https://bqsb.rarb.cn/profile/bqjy/yll.png',
        'https://bqsb.rarb.cn/profile/bqjy/nfx.png',
        'https://bqsb.rarb.cn/profile/bqjy/yll.png',
        'https://bqsb.rarb.cn/profile/bqjy/nfx.png',
        'https://bqsb.rarb.cn/profile/bqjy/yll.png',
        'https://bqsb.rarb.cn/profile/bqjy/nfx.png',
        'https://bqsb.rarb.cn/profile/bqjy/yll.png',
        'https://bqsb.rarb.cn/profile/bqjy/nfx.png',
        'https://bqsb.rarb.cn/profile/bqjy/yll.png',
        'https://bqsb.rarb.cn/profile/bqjy/nfx.png'
      ],
      tranCopyrightUsageDist: [],
      PROJECT_BASE_URL: process.env.VUE_APP_SERVE_PATH,
      articleList: []
    };
  },
  async mounted() {
    if (this.$route.query.infoMsg) {
      localStorage.setItem('bqsb_login', this.$route.query.infoMsg);
      location.href = 'https://www.rabqfw.com/web/#/';
      return;
    }
    indexStat().then((res) => {
      this.statData = res.data;
    });
    this.getCopyrightListData();
    this.getCopyrightWorksListData();
    this.getArticleListData();
    await this.setDist();
    this.getTranCopyrightDemandList();
  },
  created() {},
  methods: {
    getArticleListData() {
      articleList({ pageNum: 1, pageSize: 3 }).then((res) => {
        this.articleList = res.rows;
      });
    },
    async setDist() {
      return Promise.all([getDict('tran_copyright_usage')]).then((rows) => {
        this.tranCopyrightUsageDist = rows[0];
      });
    },
    getCopyrightListData() {
      registrationList(this.copyrightParams).then((res) => {
        this.copyrightList = res.rows.map((row) => {
          return {
            ...row,
            copyrightImage: `${this.PROJECT_BASE_URL.substring(0, this.PROJECT_BASE_URL.length)}${row.copyrightImage.split(',')[0]}`
          };
        });
      });
    },
    getCopyrightWorksListData() {
      worksList(this.copyrightWorksParams).then((res) => {
        this.productList = res.rows.map((row) => {
          return {
            ...row,
            worksImage: `${this.PROJECT_BASE_URL.substring(0, this.PROJECT_BASE_URL.length)}${row.worksImage.split(',')[0]}`
          };
        });
      });
    },
    async getTranCopyrightDemandList() {
      const copyrightDemandList = (await getTranCopyrightDemandUnAuth(this.copyrightDemandParams)).rows;
      this.demandList = [];
      const demandList1 = {
        index: 1,
        item: copyrightDemandList.slice(0, 3).map((row) => {
          return {
            copyrightUsageName: getTextByValue(this.tranCopyrightUsageDist, row.copyrightUsage),
            otherFile: row.otherFiles ? `${this.PROJECT_BASE_URL.substring(0, this.PROJECT_BASE_URL.length)}${row.otherFiles.split(',')[0]}` : null,
            ..._.pick(row, ['id', 'companyName', 'copyrightTypeName'])
          };
        })
      };
      const demandList2 = {
        index: 2,
        item: copyrightDemandList.slice(3, 6).map((row) => {
          return {
            copyrightUsageName: getTextByValue(this.tranCopyrightUsageDist, row.copyrightUsage),
            otherFile: row.otherFiles ? `${this.PROJECT_BASE_URL.substring(0, this.PROJECT_BASE_URL.length)}${row.otherFiles.split(',')[0]}` : null,
            ..._.pick(row, ['id', 'companyName', 'copyrightTypeName'])
          };
        })
      };
      this.demandList.push(demandList1);
      this.demandList.push(demandList2);
    },
    handleCopyrightTypeSelect(item, index) {
      this.copyrightTypeActive = index;
      this.copyrightParams.copyrightTypeOn = item.value;
      this.getCopyrightListData();
    },
    findCopyright() {
      if (this.findName) {
        this.$router.push(`/copyrightDisplay?findName=${this.findName}`);
      } else {
        this.$router.push('/copyrightDisplay');
      }
    },
    clickNewsMenu(item) {
      if (item.title === '版权发布') {
        if (localStorage.getItem('bqsb_login')) {
          this.$router.push(item.url);
        } else {
          this.topDialogVisible = false;
          this.topDialogVisible = true;
        }
      } else {
        this.$router.push(item.url);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.banner-box {
  background: url('../assets/image/banner1.jpg') round;
  height: 560px;
}
.search-box {
  padding: 180px 0 0 0;
  .search {
    width: 42%;
    padding: 6px;
    background: rgb(225, 240, 253);
    border: 1px solid white;
    border-radius: 4px;
  }
}
.number-box {
  background: #f3f9ff;
  padding: 30px 0;
  .height-icon {
    height: 48px;
  }
  .border-right {
    border-right: 1px solid #eaeaea;
  }
}
.show-box {
  padding: 80px 40px;
  img.title {
    margin-bottom: 60px;
    height: 80px;
  }
  .left-box {
    width: 23%;
    background: #3f96ff;
    border-radius: 10px;
    //height: 580px;
    padding: 38px 0;
    .item {
      padding: 30px 0;
    }
    .item.active {
      background: linear-gradient(to right, #ffffff, #e7f4ff);
    }
    .left-line {
      width: 4px;
      height: 32px;
      background: #3f96ff;
      border-radius: 0 5px 5px 0;
      margin-right: 20px;
    }
    .height-icon {
      height: 32px;
    }
    .title {
      margin-left: 30px;
    }
  }
  .right-box {
    background: #f3f9ff;
    //height: 700px;
    border-radius: 10px;
    padding: 10px 20px;
    .item {
      height: 320px;
      width: 31%;
      border-radius: 10px;
      overflow: hidden;
      margin: 15px 1%;
    }
    .image {
      height: 230px;
    }
  }
}
.news-box {
  background: url('../assets/image/08.png') no-repeat;
  background-size: 100% 100%;
  padding: 50px 0;
  .left {
    width: 60%;
    .news-item {
      padding: 30px 0;
      border-bottom: 1px solid #eeeeee;
    }
  }
  .right {
    width: 40%;
  }
}
.product-box {
  padding: 80px 40px;
  background: url('../assets/image/t5-b.png') no-repeat center center / cover;
  .title {
    height: 80px;
    margin-bottom: 60px;
  }
  .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .grid-content {
    .picture {
      width: 230px;
      height: 230px;
      border-radius: 50%;
      position: absolute;
    }
    .picture-b {
      background: #fff;
      width: 240px;
      height: 240px;
      border-radius: 50%;
      top: -5px;
      position: absolute;
    }
    .grid-content-box {
      background: white;
      width: 100%;
      border-radius: 10px;
      padding: 200px 20px 40px;
      margin-top: 80px;
      transition: all 0.3s;
    }
    .grid-content-box:hover {
      background: #4199ff;
      p,
      .grid-content-title {
        color: white !important;
      }
    }
  }
}
.demand-box {
  padding: 80px 40px;

  .title {
    height: 80px;
    margin-bottom: 60px;
  }
  .text-box {
    position: relative;
    top: -10px;
    padding: 20px 20px 10px;
  }
  .demand-picture {
    width: 100%;
    height: 320px;
  }
}
.company-box {
  background: url('../assets/image/bg.png') no-repeat;
  background-size: 100% 100%;
  padding: 50px 0;
  overflow-x: hidden;
  .title {
    margin-bottom: 60px;
  }
  .warp {
    height: 100px;
    width: 100%;
  }
  .marquee-box {
    overflow: auto;
    .marquee-item {
      min-width: 280px;
      width: auto;
      padding: 0 10px;
      height: 80px;
      background: #ffffff;
      border-radius: 10px;
      text-align: center;
      margin-right: 20px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      //flex: 0;
      //flex-basis: auto;
      img {
        height: 60px;
      }
    }
  }
}

/deep/.el-select .el-input {
  width: 120px;
}
/deep/.input-with-select .el-input-group__prepend,
/deep/.el-input__inner {
  background-color: rgba(255, 255, 255, 0);
  border: none;
  /*border-right: 1px solid white;*/
  font-size: 18px;
  color: #3f96ff;
}

/deep/.el-input__inner::placeholder {
  color: rgba(63, 150, 255, 0.5);
}
/deep/.el-input-group__append {
  width: 120px;
  text-align: center;
  border: none;
  background: #3f96ff;
  color: #ffffff;
  border-radius: 5px;
}
/deep/.el-carousel__container {
  height: 500px;
}
/deep/.el-carousel__arrow--right {
  right: 0;
}
/deep/.el-carousel__arrow--left {
  left: 0;
}
</style>
